import React, { Fragment, useEffect, useRef, useState } from 'react'
import Image from 'next/image'
import { Box, Button, Flex, Text, ButtonProps } from '@chakra-ui/react'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from '@icons/index'
import { useResponsive } from '@common/hooks'
import ProductFlashSale from './product-flashsale'
import { convertToCloudFrontImage } from '@lib/util/convert-cloudfront-image'
import Slider from 'react-slick'

export interface ImageCustomType {
  id: string
  url: string
  media_type: string
}

interface PropsType {
  images: ImageCustomType[]
  variantImage?: string | null
  productId?: string
  price?: any
}

interface ButtonPropsType extends ButtonProps {
  onClick?: () => void
  isRight: boolean
}

const BG_BUTTON = '#0000005a'

const ButtonSwitchImage: React.FC<ButtonPropsType> = ({ isRight, ...rest }) => {
  return (
    <Button
      {...rest}
      position="absolute"
      display="flex !important"
      alignItems="center"
      justifyContent="center"
      zIndex={1}
      bg={BG_BUTTON}
      _hover={{ bg: BG_BUTTON }}
      _focus={{ bg: BG_BUTTON }}
      borderRadius={0}
      h={16}
      w={12}
      px={0}
    >
      {isRight ? (
        <MdKeyboardArrowRight
          width={18}
          height={18}
          color={'white'}
          strokeWidth={5}
        />
      ) : (
        <MdKeyboardArrowLeft
          width={18}
          height={18}
          color={'white'}
          strokeWidth={5}
        />
      )}
    </Button>
  )
}

const ProductMedia = ({
  images,
  variantImage,
  productId,
  price
}: PropsType) => {
  const [num, setNum] = useState(0)
  const { mdMax, smMax } = useResponsive()
  const slider = useRef<Slider | null>(null)

  useEffect(() => {
    if (images && images.length > 0 && variantImage) {
      const imageIndex = images.findIndex((image) => image.url === variantImage)
      if (imageIndex !== -1) {
        setNum(imageIndex)
      }
    }
  }, [variantImage])

  useEffect(() => {
    setNum(0)
  }, [productId])

  useEffect(() => {
    slider?.current?.slickGoTo(num)
  }, [num])

  const filteredImages = images.filter((image) => image?.media_type === 'image')

  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <ButtonSwitchImage isRight={false} left={0} />,
    nextArrow: <ButtonSwitchImage isRight={true} right={0} />,
    appendDots: (dots: any) => {
      return (
        <Flex w={'100%'} gap={0} justifyContent={'center'} mt={5} bottom={-10}>
          {dots}
        </Flex>
      )
    },
    customPaging: () => {
      return (
        <Box
          w={2.5}
          h={2.5}
          bg={'bgBold'}
          borderRadius={'50%'}
          border={'none'}
          borderColor={'white'}
          cursor={'pointer'}
          transition={'ease-inb'}
        />
      )
    }
  }

  return (
    <Fragment>
      <Flex
        width={'100%'}
        maxH={mdMax ? 'auto' : 873}
        alignItems={'center'}
        position={'relative'}
        alignContent={'center'}
        marginBottom={4}
      >
        {filteredImages?.length ? (
          <Box w="100%">
            <Slider ref={slider} {...settings} className="product-media">
              {filteredImages.map((image, index) => (
                <Image
                  key={`product-media-${index}`}
                  src={convertToCloudFrontImage(image?.url ?? '')}
                  alt={(image?.id as string) || 'product image'}
                  sizes="(max-width: 767px) 100vw, 50vw"
                  style={{
                    width: '100%',
                    height: 'auto'
                  }}
                  width={722}
                  height={873}
                  priority
                />
              ))}
            </Slider>
          </Box>
        ) : null}

        {price && price?.cheapestPrice?.percentage_diff > 0 && (
          <Flex
            bg={'red'}
            position={'absolute'}
            top={0}
            left={0}
            w={smMax ? 16 : 20}
            h={smMax ? 20 : 24}
            ml={5}
            pt={3}
            px={2}
            pb={2}
            flexDirection={'column'}
            justifyContent={'end'}
            alignItems={'center'}
          >
            <Flex gap={2} flexDirection={'column'} mb={2}>
              <Text textStyle={'saleTextMedium'} color={'white'}>
                {price?.cheapestPrice?.percentage_diff ?? 0}%
              </Text>
              <Text textStyle={'saleTextMedium'} color={'white'}>
                Off
              </Text>
            </Flex>
          </Flex>
        )}
      </Flex>

      <ProductFlashSale
        productId={productId ?? ''}
        isRegionSale={price && price?.cheapestPrice?.percentage_diff > 0}
      />
    </Fragment>
  )
}

export default ProductMedia
