import axios from 'axios'
import { BACKEND_URL_STORE } from '@constants/server.constant'

export const listBrands = () =>
  axios.get(`${BACKEND_URL_STORE}/brand`, { withCredentials: true })

export const getBrand = async (handle: string) => {
  const { data } = await axios.get(
    `${BACKEND_URL_STORE}/brand/handle/${handle}`,
    {
      withCredentials: true
    }
  )
  return data
}

export const listBrandsByCollectionId = async (collectionId: string) => {
  const { data } = await axios.get(
    `${BACKEND_URL_STORE}/brand/collection/${collectionId}`,
    {
      withCredentials: true
    }
  )
  return data
}
