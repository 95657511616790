import {
  Box,
  Button,
  Flex,
  IconButton,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import useGA4DataLayer from '@common/hooks/use-ga4-data-layer'
import { IProductItem } from '@common/interface/product'
import PreOrderModal from '@components/modals/pre-order-modal'
import RouterUrl from '@constants/router.constant'
import AddToCartIcon from '@icons/add-to-cart-icon'
import {
  AdditionalCartProvider,
  useAdditionalCart
} from '@lib/context/additional-cart-context'
import { canBuys, canPreOrders } from '@lib/util/can-buy'
import { convertToCloudFrontImage } from '@lib/util/convert-cloudfront-image'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useMemo } from 'react'

interface ProductCardItemPropsType {
  item: IProductItem
  bg?: string
  color?: string
}

const Product = ({ item, color, bg, ...props }: ProductCardItemPropsType) => {
  const link = `${RouterUrl.PRODUCT.ROOT}/${item.handle ? item.handle : ''}`

  const router = useRouter()

  const { selectItemTag } = useGA4DataLayer()

  const { isOpen, onClose, onOpen } = useDisclosure()

  const { addToCart, isLoading: isLoadingAddToCart } = useAdditionalCart()

  const { title, thumbnail, price, variants } = item

  const inStock = useMemo(() => {
    return canBuys(variants)
  }, [variants])

  const isPreOrder = useMemo(() => {
    return canPreOrders(variants)
  }, [variants])

  return (
    <Flex
      flexDirection="column"
      width={{
        base: '100% !important',
        xl: '100% !important',
        '2xl': '325px'
      }}
      h="100%"
      justifyContent="space-between"
      {...props}
      data-aos="fade-up"
    >
      <PreOrderModal
        isOpen={isOpen}
        onCloseHandler={onClose}
        product={item as IProductItem}
      />

      <Box>
        <Box
          position={'relative'}
          height={'auto'}
          onClick={() => {
            selectItemTag([item])
          }}
        >
          <Link href={link} passHref>
            <Image
              src={convertToCloudFrontImage(thumbnail ?? '')}
              alt={title}
              sizes="(max-width: 767px) 50vw, (max-width: 991px) 33vw, 25vw"
              style={{
                width: '100%',
                height: 'auto'
              }}
              width={360}
              height={435}
              priority={true}
            />
          </Link>
          {price && price.price_type === 'sale' && (
            <Flex
              bg={bg ?? 'red'}
              position={'absolute'}
              top={0}
              left={0}
              w={{ base: 10, sm: 14 }}
              h={{ base: 12, sm: 16 }}
              ml={{ base: 3, sm: 4 }}
              pt={{ base: 4, sm: 3 }}
              pb={{ base: 1, sm: 2 }}
              flexDirection={'column'}
              justifyContent={'end'}
              alignItems={'center'}
            >
              <Box gap={0}>
                <Text textStyle={'saleText'} color={'white'}>
                  {price.difference || 0}%
                </Text>
                <Text textStyle={'saleText'} color={'white'}>
                  Off
                </Text>
              </Box>
            </Flex>
          )}
        </Box>

        <Box mt={4}>
          <Link href={link} passHref>
            <Text textStyle="productItemText" fontWeight={400}>
              {title}
            </Text>
          </Link>
        </Box>
      </Box>

      <Box mt={3}>
        {inStock && (
          <div className="flex items-center gap-x-2">
            {price ? (
              <>
                {price.price_type === 'sale' && (
                  <Text
                    color={'rgb(108 108 108)'}
                    textDecoration={'line-through'}
                    textStyle={'productItemTextDecorator'}
                  >
                    {price.original_price}
                  </Text>
                )}
                <Text
                  color={price.price_type === 'sale' ? 'orange' : 'black'}
                  textStyle={'productItemText'}
                >
                  {price.calculated_price}
                </Text>
              </>
            ) : (
              <div
                className="w-20 h-6 animate-pulse"
                style={{ color: 'rgb(108 108 108)' }}
              ></div>
            )}
          </div>
        )}
        {!inStock && (
          <Flex
            gap={2}
            flexDirection={{
              base: 'column',
              sm: 'column',
              md: 'row',
              xl: 'row'
            }}
          >
            <Flex gap={2}>
              {price != null && (
                <>
                  <Text
                    color={'rgb(108 108 108)'}
                    textDecoration={'line-through'}
                    textStyle={'productItemTextDecorator'}
                  >
                    {price.original_price}
                  </Text>

                  {price.price_type === 'sale' && (
                    <Text
                      color={'rgb(108 108 108)'}
                      textStyle={'productItemText'}
                    >
                      {price.calculated_price}
                    </Text>
                  )}
                </>
              )}
            </Flex>
            <Text color={'orange'} textStyle={'productItemText'}>
              Sold Out
            </Text>
          </Flex>
        )}
        <Flex alignItems={'center'} gap={3} mt={3}>
          {inStock && (
            <IconButton
              aria-label="add-to-cart"
              variant="ghost"
              onClick={addToCart}
              cursor={'pointer'}
              icon={<AddToCartIcon />}
              isDisabled={isLoadingAddToCart}
            />
          )}
          {!inStock && isPreOrder ? (
            <Button
              bg={bg || 'bg'}
              px={{ base: 12, sm: 12, md: 16, xl: 16 }}
              py={4}
              w={0}
              h={0}
              borderRadius={24}
              color={color || 'black'}
              onClick={onOpen}
              _hover={{
                bg: bg || 'bg'
              }}
            >
              <Text color={color || 'black'} textStyle="productItemText">
                Pre-order
              </Text>
            </Button>
          ) : inStock ? null : (
            <Button
              bg={bg || 'bg'}
              px={{ base: 10, sm: 10, xl: 16 }}
              py={4}
              w={0}
              h={0}
              borderRadius={24}
              color={color || 'black'}
              _hover={{
                bg: bg || 'bg'
              }}
              isDisabled={true}
            >
              <Text color={color || 'black'} textStyle="productItemText">
                Sold out
              </Text>
            </Button>
          )}
          {inStock && (
            <Button
              bg={bg || 'bg'}
              px={{ base: 10, sm: 10, md: 14, xl: 14 }}
              py={4}
              w={0}
              h={0}
              isDisabled={!inStock}
              borderRadius={24}
              color={color || 'black'}
              onClick={() => {
                selectItemTag([item])
                router.push(link)
              }}
              _hover={{
                bg: bg || 'bg'
              }}
            >
              <Text color={color || 'black'} textStyle="productItemText">
                Buy
              </Text>
            </Button>
          )}
        </Flex>
      </Box>
    </Flex>
  )
}

const ProductCardItem = (props: ProductCardItemPropsType) => {
  return (
    <AdditionalCartProvider variants={props.item.variants} product={props.item}>
      <Product {...props} />
    </AdditionalCartProvider>
  )
}

export default ProductCardItem
